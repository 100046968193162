
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"093efd68a8a48eb2d2766af9c033ec7ba2bd21fc":"$$ACTION_0","41e9a38b681e8603c56406bfc8498c4c01baee9d":"signIn","875be12dc5e1fb7798315fb15ca8125c0af1c032":"resetPassword","9696dcb65c53a6f52fc8c1e26145c1d8fccf3720":"$$ACTION_1","af014cdead5bb629959270ff21cb526d6e8ba71a":"sendMagicLink","f8598064aa4911cc95b75abfc96b8cf1289f50b1":"$$ACTION_2"} */ export var sendMagicLink = createServerReference("af014cdead5bb629959270ff21cb526d6e8ba71a");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var signIn = createServerReference("41e9a38b681e8603c56406bfc8498c4c01baee9d");
export var resetPassword = createServerReference("875be12dc5e1fb7798315fb15ca8125c0af1c032");

